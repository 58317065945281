/**
  Pas niet de document font-size aan!
  Waarom: https://engageinteractive.co.uk/blog/em-vs-rem-vs-px
 */
//
//// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
//@import url('../fonts/Futura_PT_Bold.css') ;
//@import url('../fonts/Futura_PT_ExtraBold.css');
//@import url('../fonts/Futura_PT_Book.css');
//@import url('../fonts/Futura_PT_Light.css' );



// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "bootstrapOverschrijven";
@import "~bootstrap/dist/css/bootstrap.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

// components
@import "button";

//
//input {
//    width: 100%;
//    max-width: 385px;
//    border: 1px solid #dee2e6;
//    padding: 6px 8px;
//    line-height: 1.5rem;
//    &:focus-visible {
//        border: 1px solid darken(#dee2e6, 15%);
//        outline: none;
//    }
//}
//button {
//    width: 100%;
//    max-width: 385px;
//    line-height: 2.5rem;
//    font-weight: bold;
//
//    &.btn-primary {
//        background-color: $kleur-geel;
//        &:hover {
//            background-color: darken($kleur-geel, 10%);
//        }
//    }
//}

#firstloadingBox {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999;

    .firstloadingImg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
    &.imgFadeOut {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 300ms linear, height 0s step-end 300ms; // what, duration, func, delay
        //
        //.firstloadingImg {
        //    top: 0;
        //    left: 0;
        //    transition-property: top, left;
        //    transition-duration: 0s, 0s;
        //    transition-delay: 5s, 5s;
        //}
    }
}

.mkgeu_titel {
    font-size: 73px;
    line-height: 80px;
    font-weight: bold;
    font-style: italic;
    color: #111b28;
    letter-spacing: -3px;
    text-align: center;

    &:after {
        display: block;
        content: ' ';
        margin-top: 48px !important;
        height: 4px;
        width: 64px;
        background-color: #23c1f4;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}
.mkgeu_intro {
    color: #111b28;
    padding-top: 48px;
    font-size: 24px;
    text-align: center;
    padding-bottom: 60px;
    font-weight: normal;
}
.listWhite {
    list-style-type: disc;
}
.listBlue {
    list-style-type: disc;
}

wiki-img, .svg {
    &.verkoop{
        fill:#58c763
    }
    &.inkoop{
        fill:#ec4863
    }
    &.engineering{
        fill:#ffb534
    }
    &.productie{
        fill:#0c67ca
    }
    &.planning{
        fill:#0c67ca
    }
    &.uren{
        fill:#0599b0
    }
    &.artikelen{
        fill:#22a7f0
    }
    &.relaties{
        fill:#d6af80
    }
    &.financieel{
        fill:#9691e8
    }
    &.systeem{
        fill:#78a3b9
    }
    &.voorraad{
        fill:#ac7839
    }
    &.rapportage{
        fill:#626262
    }
    &.meldingen{
        fill:#ca73cd
    }
    &.all_else{
        fill:#111b28
    }
}



input[type=checkbox]{
    accent-color: $kleur-cyan2;
}
body {
  background-color: $kleur-offwhite !important;
  font-family: "futura-pt", serif, sans-serif;
  font-size: 1rem ; /* bugfix !important: bootstrap bug veroorzaakt in legacy (webshop) 10px lettertype */
    color: $kleur-donkersteBlauw;
}

.navbar .nav-link.active {
    color: $kleur-cyan;
}

main {
  margin-top: calcRem(64px);
  margin-bottom: calcRem(64px);
}

h1, h2, h3 {
  font-weight: bold;
}
h1 {
  font-size: calcRem(36px);
}
h2 {
    font-size: calcRem(28px);
}
h3 {
  font-size: 1.375rem;
}
h4 {
    color: $kleur-cyan;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0;
}
p {
    margin-bottom: 0.6rem;
}
code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
    line-height: 100%;
    background-color: #eee;
    font-size: 0.8rem;
    color: $kleur-donkerBlauw;
    letter-spacing: 0.05px;
    padding: 0.2em;
    word-break: break-word;
}
a:not(.btn) {
    color: $kleur-donkerBlauw;
    text-decoration: underline;
    &:hover {
        color: $kleur-cyan;
    }
}
.asLink {
    color: $kleur-donkerBlauw  !important;
    text-decoration: underline !important;
    &:hover {
        color: $kleur-cyan   !important;
        cursor: pointer;
    }
}
.btn {
    font-weight: bold;
    &.active {
        color: $kleur-donkersteBlauw !important;
        background-color: $kleur-grijsBlauw2 !important;
        border: 1px solid $kleur-grijsBlauw !important;
        -moz-box-shadow:    inset 0 0 10px #6c757db8 !important;
        -webkit-box-shadow: inset 0 0 10px #6c757db8 !important;
        box-shadow:         inset 0 0 10px #6c757db8 !important;
    }

    &.btn-icon {
        min-height: 45px !important;
        min-width: 55px !important;
    }
    &.btn-secondary {
        color: $kleur-donkersteBlauw;
        background-color: $kleur-cyan !important;
        border-color: darken($kleur-cyan, 5%) !important;
        &:hover {
            color: $kleur-donkersteBlauw;
            background-color: darken($kleur-cyan, 5%) !important;
            border-color: darken($kleur-cyan, 5%) !important;
        }
    }
}
//ul {
//    list-style: disc;
//}
//ol {
//    list-style: decimal;
//}
.btn-lg {
    text-align: left;
    padding: 8px 32px !important;
    line-height: 24px;
}
.btn-250 {
    @extend .btn-lg;
    min-width: 250px !important;
}

.append-left-arrow {
    text-align: right;
    padding: 8px 32px !important;
    line-height: 24px;
    &::before {
        content: '\f053';
        display: inline-block;
        font-family: $font-icons;
        font-weight: 900;
        float: left;
        color: $kleur-donkerBlauw;
        padding-right: 20px;
    }
}
.append-right-arrow {
    text-align: left;
    padding: 8px 32px !important;
    line-height: 24px;
    &::after {
        content: '\f054';
        display: inline-block;
        font-family: $font-icons;
        font-weight: 900;
        float: right;
        color: $kleur-donkerBlauw;
        padding-left: 20px;
    }
}



.text-cyan {
    color: $kleur-cyan;
}
.text-donkersteblauw {
    color: $kleur-donkersteBlauw;
}
.text-donkerBlauw {
    color: $kleur-donkerBlauw;
}

.tabbladen {
    padding-bottom: 0;

    .nav.nav-tabs {
        border-bottom: none;
        font-weight: normal;
    }

    .nav-link.active {
        background-color: white;
        border-bottom-color: white;
    }
}

.selectpicker.header-displaynone + button + .dropdown-menu  {
    .dropdown-header, li:not(.optgroup-1) + .dropdown-divider {
        display: none;
    }
}

.groepsrechten {
    td i.fa {
        font-size: calcRem(13px);
        &.fa-unlock {
            color: $green;
        }
        &.fa-lock {
            color: $kleur-grijsBlauw2;
        }
    }
}
ul, menu {
    list-style: initial; /* Restore the browser's default list styling */
    margin: revert;     /* Use browser's default margin */
    padding: revert;    /* Use browser's default padding */
}

ol {
    list-style: decimal; /* Restore the browser's default list styling */
    margin: revert;     /* Use browser's default margin */
    padding: revert;    /* Use browser's default padding */
}

.skeleton::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
        );
        animation: shimmer 5s infinite;
        content: '';


    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.text-red {
    color: $kleur-sterkRood;
    &.icon-link:hover {
       color: darken($kleur-sterkRood, 10%);
    }
}
.icon-link {
    font-size: 1.1em;
    &:hover {
        cursor: pointer;
    }
}
footer {
    background-color: #111b28;
    padding-top: 30px;


    .footer_container {
        color: white;

        h4 {
            font-size: 16px;
            margin-top: 16px;
            margin-bottom: 4px;
            color: #888;
            line-height: 20px;
            font-weight: 700;
        }
        h3 {
            color: $kleur-cyan;
            font-weight: bold;
            font-style: normal;
            font-size: 18px;
            //margin-left: 32px;
        }

        a:visited, a:active, a:link {
            border: none;
            text-decoration: none;
            color: #fff;
            float: none;
        }

        a:hover, a h4:hover {
            border: none;
            text-decoration: underline;
            color: $kleur-cyan;
        }

        #footer_foot {
            padding-top: 30px;

            #foot_support {
                font-size: 16px;
                color: #fff;
                font-weight: 700;
                clear: both;
                vertical-align: bottom;
                padding: 8px;
                bottom: 0;
                background-color: $kleur-donkersteBlauw;
                border-top: 1px solid #6d6d6d;
            }

            #foot_note {
                font-size: 9px;
                color: #E7E7E7;
                clear: both;
                vertical-align: bottom;
                padding: 0 0 8px 8px;
                bottom: 0;
                background-color: $kleur-donkersteBlauw;
                border-top: 1px solid #6d6d6d;
            }

            .footer_txt {

                padding: 8px;
                margin: 0;
            }
        }


    }

}
.score-tag-wiki{
    background-color: rgb(153,153,153);
}

.nav.nav-pills .nav-link.active {
    background-color: $kleur-cyan !important;
}

.btn-close {
    opacity: 5 !important;
    background-color: transparent !important;
    font-size: 0.6rem !important;
    min-width: 1rem !important;
    font-weight: 700 !important;
    border: none !important;
    box-shadow: none !important;
    --bs-btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(35, 193, 244)'><path%20d='M.293.293a1%201%200%200%201%201.414%200L8%206.586%2014.293.293a1%201%200%201%201%201.414%201.414L9.414%208l6.293%206.293a1%201%200%200%201-1.414%201.414L8%209.414l-6.293%206.293a1%201%200%200%201-1.414-1.414L6.586%208%20.293%201.707a1%201%200%200%201%200-1.414z'/></svg>") !important;
}

.ico_icon_box {
    width: 128px !important;
}

.menuListItemActive {
    border-top: 2px solid $kleur-donkerBlauw;
    background-color: $kleur-cyan;

    a {
        color: white;
        img{
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%) !important;
        }
    }

    &::after {
        background-color: $kleur-cyan !important;

    }

    &:first-child::before {

        background-color: $kleur-cyan;

    }

    .menuIcon {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
    }



    &:hover:not(.disabled) {
        a {
            color: white;
        }

        .menuIcon {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
        }
    }
}