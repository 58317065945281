
audio, canvas, embed, iframe, img, object, svg, video {
  display: inline-block !important;
}
h2, h3 {
  margin-bottom: 20px;
}
li {
  padding-bottom: 8px;
}

body .form-control {
  background-color: white;
}
body:not(#legacy) .modal[style]:not(.show) {
  display: none !important;
}

.btn-link {
  color: $kleur-donkersteBlauw;
  text-decoration: underline;

  &:hover{
    color: $kleur-cyan2;
  }

}

.card {
  border-radius: 0;
  .card-header {
    font-weight: bold;
    text-align: center;
  }
}



.mkgTopMenu nav {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0;
  text-transform: uppercase;
  font-size: calcRem(12px);

  .mkgLogo {
    height: calcRem(40px);
    width: calcRem(52px);
    //margin: calcRem(7px) 0;
    background: url("/images/logo_spite.png") top center;
    background-size: 100%;
    cursor: pointer;
    -webkit-animation: logoPlaySmall 1.4s steps(15) forwards;
    animation: logoPlaySmall 1.4s steps(15) forwards;
    float: left;
  }
  &.navbar {
    padding: 0;

  }
  .nav-link {
    padding: 0.8rem 1rem 0 1rem !important;
    color: $kleur-donkersteBlauw;
    line-height: calcRem(26px);

    &:hover{
      color: $kleur-cyan2;
      text-decoration: underline;
    }
    &.dropdown-toggle:hover{
      text-decoration: none;
    }
  }
  .dropdown-menu {
    font-size: calcRem(12px);
    min-width: 10rem;
    border-radius:0;

    .dropdown-item:active {
      background-color: $kleur-cyan2;
    }
  }
}

//.table td, .table th {
//    padding: calcRem(8px);
//}

button:not(.btn) {
  color: inherit;
  background-color: inherit;
  border: inherit;
  padding: initial;
}

.modal-header .close{
  border-bottom: none;
}

.letop_light{
  background-color: initial;
}
.alert-dismissible .close {
 padding: 8px 16px !important;
}